import React from 'react';

const CrosshairSvg = (props: any) => (
  <svg
    className="crosshair"
    viewBox="0 0 22 22"
    height={22}
    width={22}
    fill="none"
    {...props}
  >
    <line x1={10.5} y1={0} x2={10.5} y2={22} />
    <line x1={0} y1={10.5} x2={22} y2={10.5} />
  </svg>
);

export default CrosshairSvg;
