import React, { MouseEventHandler, useRef } from 'react';
import classnames from 'classnames';
import SliderNav from './sliderNav';
import SliderPagination from './sliderPagination';
import Swiper from 'swiper';
// @ts-ignore
import { device } from 'helpers/helpers';
import useIntersectionObserverInitializer from '../../hooks/useIntersectionObserverInitializer';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';

// TODO must be better place to put this
export const initLegacySlider = (swiper: HTMLDivElement) => {
  const container = swiper.querySelector('.swiper-container');
  const navNext = swiper.querySelector('.slider-nav__arrow.next');
  const navPrev = swiper.querySelector('.slider-nav__arrow.prev');
  const pagination = swiper.querySelector('.slider-pagination');

  const isInline: boolean = swiper.classList.contains('inline');
  const isMobile: boolean = device().isMobile;

  const getSwiperOptions = () => {
    let options = {};
    if (isInline) {
      options = {
        ...options,
        slidesPerView: 3,
        spaceBetween: 20,
      };
    }
    if (isMobile) {
      options = {
        ...options,
        spaceBetween: 36,
      };
    }
    if (isInline && isMobile) {
      options = {
        ...options,
        slidesPerView: 1.25,
        spaceBetween: 20,
        pagination: {
          el: pagination,
          clickable: true,
        },
      };
    }
    return options;
  };

  const options = {
    navigation: {
      nextEl: navNext,
      prevEl: navPrev,
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3,
      loadOnTransitionStart: true,
    },
    ...getSwiperOptions(),
  };

  // console.debug('swiper options', options);
  // @ts-ignore
  new Swiper(container, options);

  // The dispatch event seems to fix bad initialization of swiper element widths...
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
    console.log('resize');
  }, 100);
};

const GatsbyImageSlide = ({
  slide,
  onClick,
}: {
  slide: AppGatsbyImageFluid;
  onClick?: MouseEventHandler;
}) => {
  return (
    <figure className="gallery-slider__slide swiper-slide" onClick={onClick}>
      <AppGatsbyImage fluid={slide} />
    </figure>
  );
};

export const GatsbyImageSlider = ({
  className,
  showNav = true,
  showPagination = false,
  slides,
  onSlideClick,
}: {
  className?: string;
  showNav?: boolean;
  showPagination?: boolean;
  slides: AppGatsbyImageFluid[];
  onSlideClick?: (slide: AppGatsbyImageFluid) => void;
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const initializer = useIntersectionObserverInitializer({
    callback: () => {
      initLegacySlider(sliderRef.current!);
      console.debug('initLegacySlider');
    },
  });

  return (
    <>
      {initializer.marker}
      <div className={classnames('slider', className)} ref={sliderRef}>
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {slides.map((slide, i) => (
              <GatsbyImageSlide
                key={i}
                slide={slide}
                onClick={onSlideClick ? () => onSlideClick(slide) : undefined}
              />
            ))}
          </div>
        </div>
        {showNav && <SliderNav />}
        {showPagination && <SliderPagination count={slides.length} />}
      </div>
    </>
  );
};

// type ImageSlide = {
//   src: string;
// };

// const ImageSlide = ({
//   slide,
//   lazy,
//   onClick,
// }: {
//   slide: ImageSlide;
//   lazy: boolean;
//   onClick?: MouseEventHandler;
// }) => {
//   const imageProps = lazy ? { 'data-src': slide.src } : { src: slide.src };
//   return (
//     <figure className="gallery-slider__slide swiper-slide" onClick={onClick}>
//       <img
//         // If lazy, we render a placeholder first
//         {...imageProps}
//         className={classnames({ 'swiper-lazy': lazy })}
//         css={{ backgroundColor: 'black', userSelect: 'none' }}
//       />
//       {/*
//       TODO remove unused code + css? (was initially used for "about" page
//       {(slide.captionTitle || slide.captionText) && (
//         <figcaption className="gallery-slider__caption">
//           {slide.captionTitle && <strong>{slide.captionTitle}</strong>}
//           {slide.captionText}
//         </figcaption>
//       )}
//        */}
//     </figure>
//   );
// };

// TODO factorize this legacy component,
// it has a lot of shared details with the gatsby-image equivalent
// const ImageSlider = <S extends ImageSlide>({
//   className,
//   showNav = true,
//   showPagination = false,
//   slides,
//   isSlideLazy = () => true,
//   onSlideClick,
// }: {
//   className?: string;
//   showNav?: boolean;
//   showPagination?: boolean;
//   slides: S[];
//   isSlideLazy?: (slide: S, slideIndex: number) => boolean;
//   onSlideClick?: (slide: S, slideIndex: number) => void;
// }) => {
//   const sliderRef = useRef<HTMLDivElement>(null);
//
//   const initializer = useIntersectionObserverInitializer({
//     callback: () => {
//       initLegacySlider(sliderRef.current!);
//       console.debug('initLegacySlider');
//     },
//   });
//
//   return (
//     <>
//       {initializer.marker}
//       <div className={classnames('slider', className)} ref={sliderRef}>
//         <div className="swiper-container">
//           <div className="swiper-wrapper">
//             {slides.map((slide, i) => (
//               <ImageSlide
//                 key={i}
//                 slide={slide}
//                 lazy={isSlideLazy(slide, i)}
//                 onClick={
//                   onSlideClick ? () => onSlideClick(slide, i) : undefined
//                 }
//               />
//             ))}
//           </div>
//         </div>
//         {showNav && <SliderNav />}
//         {showPagination && <SliderPagination count={slides.length} />}
//       </div>
//     </>
//   );
// };
//
// export default ImageSlider;
