import { graphql, useStaticQuery } from 'gatsby';
import { AppGatsbyImageFluid } from '../../../primitives/appImage';

const useHowItWorksImages = (): AppGatsbyImageFluid[] => {
  const data: any = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: ASC }
          filter: { relativePath: { glob: "home_howitworks-*" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(
                  maxWidth: 290
                  quality: 80
                  srcSetBreakpoints: [200, 250]
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    `,
  );
  return data.allFile.edges.map((edge: any) => edge.node.childImageSharp.fluid);
};

export default useHowItWorksImages;
