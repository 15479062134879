import React from 'react';

const SliderPauseSvg = (props: any) => (
  <svg
    className="slider-pause"
    height={16}
    width={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <line x1={6} y1={2} x2={6} y2={14} />
    <line x1={10} y1={2} x2={10} y2={14} />
  </svg>
);

export default SliderPauseSvg;
