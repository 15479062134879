import React, { MouseEventHandler } from 'react';
import { ApplyMountEffect, cardRevealEffect } from '../helpers/reveals';
import { getProfessionMessage } from '../appMessageDefinitions';
import {
  AppInternalLink,
  AppInternalPath,
  AppInternalPaths,
} from '../primitives/appLink';
import classnames from 'classnames';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../primitives/appImage';
import { H4 } from '../primitives/appTitle';
import {
  ShIntlMessageDefinition,
  ShText,
  useShIntlLocale,
} from '@shoootin/translations';
import useProfessionFirstImages from '../queries/useProfessionFirstImages';
import { forceImageMaxWidth } from '../appGatsbyImageUtils';
import { ProfessionShootingType } from '../appConstants';

const CoverMetierItem = ({
  image,
  target,
  title,
  cta,
}: {
  image: AppGatsbyImageFluid;
  target: AppInternalPath;
  title: ShIntlMessageDefinition;
  cta: {
    label: ShIntlMessageDefinition;
    onCTAPress: MouseEventHandler;
  };
}) => {
  return (
    <AppInternalLink to={target}>
      <div className="cover-wrapper">
        <div className={classnames('cover preview')}>
          <div className="cover__link" />
          <AppGatsbyImage
            className="cover__image"
            fluid={image}
            style={{ position: 'absolute' }}
          />
          <div className="cover__legend__metier">
            <H4>
              <ShText message={title} />
            </H4>

            <span
              className="link invert"
              onClick={(e) => {
                // Because there's already an outer AppInternalLink
                e.stopPropagation();
                e.preventDefault();
                cta.onCTAPress(e);
              }}
            >
              <ShText message={cta.label} />
            </span>
          </div>
        </div>
      </div>
    </AppInternalLink>
  );
};

type CoverMetiersProps = {
  professions: ProfessionShootingType[];
  cta: {
    label: ShIntlMessageDefinition;
    onCTAPress: (profession: ProfessionShootingType) => void;
  };
};

const CoverMetiers = ({ professions, cta }: CoverMetiersProps) => {
  const locale = useShIntlLocale();
  const professionFirstImages = useProfessionFirstImages(locale);
  return (
    <div className="cover-tiles">
      {professions.map((profession, i) => (
        <ApplyMountEffect
          key={i}
          index={i}
          effect={(el, index) =>
            cardRevealEffect(el, index, { viewFactor: 0, delay: 0 })
          }
        >
          {(ref) => {
            const image = forceImageMaxWidth(
              professionFirstImages[profession],
              200,
            );
            return (
              <div ref={ref} className="cover-tile">
                <CoverMetierItem
                  image={image}
                  title={getProfessionMessage(profession, 'short')}
                  target={AppInternalPaths.profession(profession)}
                  cta={{
                    label: cta.label,
                    onCTAPress: () => cta.onCTAPress(profession),
                  }}
                />
              </div>
            );
          }}
        </ApplyMountEffect>
      ))}
    </div>
  );
};

export default CoverMetiers;
