import Swiper from 'swiper';
import { device } from 'helpers/helpers';

export class FrontGallerySwiper {
  constructor(element) {
    this.element = element;
    this.scrollLockRecord = 0;
    this.$refs = {
      close: this.element.querySelector('.gallery-close'),
      navigation: this.element.querySelector('.gallery-nav'),
      thumbnails: this.element.querySelector('.gallery-thumbs'),
      toggle: this.element.querySelector('.gallery-nav__toggle'),
      buttons: {
        next: this.element.querySelector('.gallery-nav__button.next'),
        previous: this.element.querySelector('.gallery-nav__button.previous'),
        play: this.element.querySelector('.gallery-nav__button.play'),
        fullscreen: this.element.querySelector(
          '.gallery-nav__button.fullscreen',
        ),
      },
    };

    this._initSwiper();
    this._initClose();
    this._initArrow();
    this._initPlay();
    this._initFullscreen();
    this.show();

    // new VideoPlayer();
  }

  lockBody() {
    this.scrollLockRecord = window.pageYOffset;
    document.body.style.top = `-${this.scrollLockRecord}px`;
    document.body.classList.add('lock');
  }
  unlockBody() {
    document.body.classList.remove('lock');
    document.body.style.top = null;
    window.scrollTo(0, this.scrollLockRecord);
  }

  show() {
    this.lockBody();
    /*
    setTimeout(() => {
      this.element.classList.add('active');
      this.lockBody();
    }, 10);
    */
    window.addEventListener('resize', this._onResize.bind(this));
  }

  close() {
    // this.element.classList.remove('active');
    this.unlockBody();
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    window.removeEventListener('resize', this._onResize.bind(this));
  }

  _onResize() {
    setTimeout(() => {
      this.thumbsSwiper.update();
      this.topSwiper.update();
    }, 1000);
  }

  _initSwiper() {
    let top = this.element.querySelector('.gallery__top');

    this.thumbsSwiper = new Swiper(this.$refs.thumbnails, {
      spaceBetween: device.isMobile ? 16 : 24,
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });

    this.topSwiper = new Swiper(top, {
      spaceBetween: 0,
      loop: true,
      loopedSlides: 5,
      navigation: {
        nextEl: this.$refs.buttons.next,
        prevEl: this.$refs.buttons.previous,
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 2,
        loadOnTransitionStart: true,
      },
      thumbs: {
        swiper: this.thumbsSwiper,
      },
    });
  }

  _initClose() {
    this.$refs.close.addEventListener('click', this.close.bind(this));
  }

  _initArrow() {
    this.$refs.toggle.onclick = () => {
      this.element.classList.toggle('thumbs-active');
    };
  }

  _initPlay() {
    this.$refs.buttons.play.addEventListener(
      'click',
      this.togglePlayback.bind(this),
    );
  }

  togglePlayback() {
    this.element.classList.toggle('playing');
    const willPlay = this.element.classList.contains('playing');
    const action = willPlay ? 'start' : 'stop';
    this.topSwiper.autoplay[action]();
  }

  _initFullscreen() {
    this.$refs.buttons.fullscreen.addEventListener(
      'click',
      this.setFullscreen.bind(this),
    );
  }

  setFullscreen() {
    if (this.element.requestFullscreen) {
      this.element.requestFullscreen();
    }
  }
}
