import { AppGatsbyImageFluid } from '../primitives/appImage';
import { graphql, useStaticQuery } from 'gatsby';
import { getFluidImage } from '../appGatsbyImageUtils';
import { useMemo } from 'react';
import { mapValues } from 'lodash';
import { UsecaseHeaderImagesQuery } from '../appGraphQLTypes';
import { UsecaseName } from '../appConstantsUsecase';

type UseUsecaseMainImage = { [key in UsecaseName]: AppGatsbyImageFluid };

export const useUsecaseHeaderImages = (): UseUsecaseMainImage => {
  const data = useStaticQuery<UsecaseHeaderImagesQuery>(graphql`
    fragment UsecaseHeaderImage on File {
      ...AppFluidHeroImageFragment
    }
    query UsecaseHeaderImages {
      kretz: file(relativePath: { eq: "usecase_kretz-header.jpg" }) {
        ...UsecaseHeaderImage
      }
      belleval: file(relativePath: { eq: "usecase_belleval-header.jpg" }) {
        ...UsecaseHeaderImage
      }
      breathe: file(relativePath: { eq: "usecase_breathe-header.jpg" }) {
        ...UsecaseHeaderImage
      }
      convini: file(relativePath: { eq: "usecase_convini-header.jpg" }) {
        ...UsecaseHeaderImage
      }
      deskeo: file(relativePath: { eq: "usecase_deskeo-header.jpg" }) {
        ...UsecaseHeaderImage
      }
      transitionid: file(
        relativePath: { eq: "usecase_transitionid-gallery-13.jpg" }
      ) {
        ...UsecaseHeaderImage
      }
      bycharlot: file(relativePath: { eq: "usecase_bycharlot-gallery-4.jpg" }) {
        ...UsecaseHeaderImage
      }
      mom: file(relativePath: { eq: "usecase_mom-header.jpg" }) {
        ...UsecaseHeaderImage
      }
      ilcottage: file(relativePath: { eq: "usecase_ilcottage-header.jpg" }) {
        ...UsecaseHeaderImage
      }
      tollbrothers: file(
        relativePath: { eq: "usecase_tollbrothers-header.jpg" }
      ) {
        ...UsecaseHeaderImage
      }
    }
  `);

  return useMemo(() => {
    return mapValues(data, (file, key) =>
      getFluidImage(file as any, `${key} hero image not found`),
    );
  }, [data]);
};

export default useUsecaseHeaderImages;
