import React from 'react';

const ChevronDownSvg = (props: any) => (
  <svg
    className="chevron-down"
    viewBox="0 0 16 16"
    height={16}
    width={16}
    fill="none"
    {...props}
  >
    <polyline points="14,5 8,11 2,5 " />
  </svg>
);

export default ChevronDownSvg;
