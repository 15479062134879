import { TimelineLite, TweenLite } from 'gsap';
import { classes, device, toArray } from 'helpers/helpers';

export default function(el = null) {
  let unmounted = false;

  const refs = {
    wrapper: el.querySelector('.hero-slider__wrapper'),
    decoration: el.querySelector('.hero-slider__decoration'),
    nav: el.querySelector('.hero-slider__nav'),
    control: el.querySelector('.hero-control input'),
    select: el.querySelector('.hero-select'),
    toggle: el.querySelector('.hero-select__toggle'),
    dropdown: el.querySelector('.hero-select__menu'),
    priceTag: el.querySelector('.hero-select__tag'),
    progress: el.querySelector('.hero-slider__progress'),
  };

  const delay = 8;
  const slides = refs.wrapper.children;
  const bullets = toArray(refs.nav.querySelectorAll('.hero-nav-bullet'));
  const choices = toArray(refs.toggle.children);
  const prices = toArray(refs.priceTag.children);
  const options = toArray(refs.dropdown.children);

  let currentIndex = 0;

  let isOnHold = false;
  let isPaused = false;

  const clearAutoplayTimeline = _ => timelines.autoplay.clear();
  const clearMasterTimeline = _ => timelines.master.clear();

  const timelines = {
    autoplay: new TimelineLite({
      paused: true,
      onComplete: clearAutoplayTimeline,
    }),
    master: new TimelineLite({ paused: true, onComplete: clearMasterTimeline }),
  };

  const setOptionClickEvent = (option, index) => {
    option.addEventListener('click', gotoSlideBypassAutoplay(index));
  };

  const getNextIndex = _ => {
    const nextIndex = currentIndex + 1;
    const isNextIndexOutOfBounds = nextIndex === slides.length;
    return isNextIndexOutOfBounds ? 0 : nextIndex;
  };

  const gotoSlideBypassAutoplay = index => event => {
    event.preventDefault();
    classes.remove(refs.select, 'opened');
    isOnHold = true;
    timelines.autoplay.resume(delay);
    gotoSlide(index);
  };
  const gotoSlide = newIndex => {
    if (newIndex === currentIndex) {
      return false;
    }

    const leavingSlide = slides[currentIndex];
    const enteringSlide = slides[newIndex];

    timelines.master
      .add([leavingSlide.tweens.zoomIn.play()])
      .add(() => {
        setFocus(leavingSlide);
        setFocus(enteringSlide);
        setFocus(enteringSlide.refs.frame);
        setFocus(leavingSlide.refs.frame);
        setFocus(refs.decoration);
        unsetActive(refs.toggle);
        unsetActive(refs.priceTag);
        classes.remove(refs.wrapper, 'first-slide-no-anim');
      }, '-=0.2')
      .add(leavingSlide.tweens.slideOut.play())
      .add(() => {
        hideSlide(leavingSlide);
        unsetActive(options[currentIndex]);
        unsetActive(choices[currentIndex]);
        unsetActive(prices[currentIndex]);
        setActive(options[newIndex]);
        setActive(prices[newIndex]);
        setActive(choices[newIndex]);
        showSlide(enteringSlide);
      })
      .add(enteringSlide.tweens.slideIn)
      .add([enteringSlide.tweens.zoomOut])
      .add(() => {
        unsetFocus(enteringSlide);
        unsetFocus(enteringSlide.refs.frame);
        unsetFocus(refs.decoration);
        setActive(refs.toggle);
        setActive(refs.priceTag);
      }, '-=0.2')
      .add(() => {
        updateIndex(newIndex);
        if (!isPaused) {
          isOnHold = false;
          startAutoplay();
        }
      })
      .play();
  };

  const updateIndex = newIndex => {
    currentIndex = newIndex;
  };

  const setNavEvents = _ => refs.bullets.forEach(setOptionClickEvent);
  const setSelectToggleEvent = _ => {
    refs.toggle.addEventListener('click', toggleSelect);
    options.forEach(setOptionClickEvent);
  };
  const setControlToggleEvent = _ => {
    refs.control.addEventListener('focus', () => {
      constTogglePlayback(true);
    });
    refs.control.addEventListener('blur', () => {
      constTogglePlayback(false);
    });
  };
  const toggleSelect = _ => {
    classes.toggle(refs.select, 'opened');
    isOnHold = refs.select.classList.contains('opened');
    isPaused = true;
    constTogglePlayback(isPaused);
  };
  const constTogglePlayback = onHold => {
    if (onHold) {
      timelines.autoplay.pause();
    } else {
      //timelines.autoplay.resume(delay);
      timelines.autoplay.resume();
    }
  };

  const init = () => {
    // classes.toggle(refs.toggle, 'active');
    // classes.toggle(refs.priceTag, 'active');

    refs.bullets = toArray(bullets);
    refs.slides = initSlides(slides);

    fixHeight();

    showSlide(slides[currentIndex]);
    setActive(choices[currentIndex]);
    setActive(prices[currentIndex]);

    setNavEvents();
    setSelectToggleEvent();
    setControlToggleEvent();

    // startAutoplay();
  };

  const fixHeight = _ => {
    if (device.isMobile) {
      el.style.height = `${el.offsetHeight}px`;
      el.style.minHeight = `${el.offsetHeight}px`;
    }
  };

  const initSlides = slides => toArray(slides).map(getSlideRefs);

  const getSlideRefs = slide => {
    slide.refs = {
      background: slide.querySelector('.hero-background'),
      frame: slide.querySelector('.hero-frame'),
    };
    slide.tweens = {
      zoomIn: TweenLite.fromTo(
        slide.refs.background,
        0.2,
        { scale: 1.025 },
        { scale: 1 },
      ).pause(),
      zoomOut: TweenLite.fromTo(
        slide.refs.background,
        0.2,
        { scale: 1 },
        { scale: 1.0625 },
      ),
      slideIn: TweenLite.fromTo(
        slide.refs.background,
        0.5,
        { x: '110%' },
        { x: '0%' },
      ),
      slideOut: TweenLite.fromTo(
        slide.refs.background,
        0.5,
        { x: '0%' },
        { x: '-110%' },
      ).pause(),
    };
    return slide;
  };

  const hideSlide = slide => (slide.style.zIndex = null);
  const showSlide = slide => (slide.style.zIndex = 0);

  const unsetFocus = el => el.classList.remove('focus');
  const setFocus = el => el.classList.add('focus');
  const unsetActive = el => el.classList.remove('active');
  const setActive = el => el.classList.add('active');

  const enterSlideWhileAutoplay = (slide, bullet) => {
    classes.add(slide.refs.background, 'pan');
    setActive(bullet);
    if (device.isMobile) {
      setActive(refs.progress);
      classes.add(refs.progress, `progress-${currentIndex}`);
    }
  };
  const switchSlideWhileAutoplay = (slide, bullet) => {
    classes.remove(slide.refs.background, 'pan');
    unsetActive(bullet);
    if (device.isMobile) {
      unsetActive(refs.progress);
      setTimeout(() => {
        classes.remove(refs.progress, `progress-${currentIndex}`);
      }, 300);
    }
    if (!isOnHold) {
      gotoSlide(getNextIndex());
    }
  };

  const startAutoplay = _ => {
    console.debug('HeroSlider startAutoplay');

    const currentSlide = slides[currentIndex];
    const currentBullet = bullets[currentIndex];

    timelines.autoplay
      .add(() => {
        enterSlideWhileAutoplay(currentSlide, currentBullet);
      })
      .add(() => switchSlideWhileAutoplay(currentSlide, currentBullet), delay)
      .play();
  };

  // Now, starting the slider delays a bit the autoplay
  const startHeroSlider = () => {
    if (unmounted) {
      console.warn(
        'startHeroSlider ignored because hero slider has been unmounted',
      );
      return;
    }
    console.debug('startHeroSlider');

    const startAutoplayDelay = 5000;

    init();
    enterSlideWhileAutoplay(slides[currentIndex], bullets[currentIndex]);

    // First slide should not have any frame in effect
    classes.add(refs.wrapper, 'first-slide-no-anim');

    const startAutoplayTimeout = setTimeout(() => {
      startAutoplay();
    }, startAutoplayDelay);

    const stopHeroSlider = () => {
      console.debug('stopHeroSlider');
      clearTimeout(startAutoplayTimeout);
      timelines.master.kill();
      timelines.autoplay.kill();
      unmounted = true;
    };

    return stopHeroSlider;
  };

  return startHeroSlider();
}
