import { TimelineLite } from 'gsap';
import { device, toArray } from '../helpers/helpers';

class Tabset {
  constructor(selector) {
    this.getRefs(selector);
    this.currentIndex = 0;
    this.direction = 'rtl';
    this.isAnimating = false;
    this.timeline = new TimelineLite();
    this.initialize();
  }

  getRefs(selector) {
    const el = document.querySelector(selector);
    const nav = el.querySelector('.tabset-nav');
    const content = el.querySelector('.tabset-content');

    this.el = el;
    this.refs = {
      nav,
      content,
      links: toArray(nav.querySelectorAll('.link')),
      panels: this.getPanelsRefs(content.children),
    };
  }
  getPanelsRefs(panels) {
    return toArray(panels).map(this.getPanelRefs);
  }

  initialize() {
    // this.setTabsetHeight();
    this.setNavEvents();
    this.setActiveLink();
    this.gotoPanel(0);
  }

  async setTabsetHeight() {
    // TODO whats the purpose of this???
    // it seems it's to ensure all tabsets have the same height so that switching from one to another does not create any jump
    // this is really annoying and hacky code, rather reimplement this another way ..
    /*
    try {
      const imagePaths = this.refs.panels.map(this.getImagesPath);
      await loadImages(imagePaths);
      const panelHeights = this.refs.panels.map(getElementHeight);
      const panelHeight = Math.max(...panelHeights, 0);
      this.refs.content.style.height = `${panelHeight}px`;
    } catch (error) {
      console.error('Error while setting tabset height');
    }
    */
  }

  setNavEvents() {
    this.refs.links.forEach(this.setLinkEvent.bind(this));
  }
  setLinkEvent(el, index) {
    el.addEventListener('click', (event) => {
      this.linkEventCallback(event, index);
    });
  }
  linkEventCallback(event, index) {
    event.preventDefault();
    if (!this.isAnimating) {
      this.gotoPanel(index);
    }
  }
  setActiveLink() {
    this.refs.links.forEach((bullet) => bullet.classList.remove('active'));
    this.refs.links[this.currentIndex].classList.add('active');
  }

  gotoPanel(index) {
    if (index !== this.currentIndex) {
      const movingPanels = {
        leaving: this.refs.panels[this.currentIndex],
        entering: this.refs.panels[index],
      };

      this.direction = Tabset.getDirection(this.currentIndex, index);
      this.currentIndex = index;
      this.isAnimating = true;

      const navDirection = this.direction === 1 ? 'ltr' : 'rtl';

      movingPanels.leaving.classList.add('active', this.direction);
      this.refs.nav.classList.add(navDirection);
      this.setActiveLink();

      this.timeline.clear();

      if (!device().isMobile) {
        this.initTimelineWithParallax(movingPanels);
        this.setTimelineForDesktop(movingPanels, navDirection);
      } else {
        this.setTimelineForMobile(movingPanels, navDirection);
      }
    } else {
      this.refs.panels[index].classList.add('active');
      this.refs.panels[index].style.zIndex = 0;
    }
  }
  initTimelineWithParallax(movingPanels) {}
  setTimelineForDesktop(movingPanels, direction) {}
  setTimelineForMobile(movingPanels, direction) {}

  static getDirection(index, nextIndex) {
    return nextIndex > index ? -1 : 1;
  }
  static getTranslateYValue(el) {
    const transformStyle = window.getComputedStyle(el).transform;
    const matrix = transformStyle.match(/^matrix\((.+)\)$/);
    if (!matrix) {
      return 0;
    }
    const matrixCoords = matrix[1].split(', ');
    return matrixCoords.length ? +matrixCoords[5] : 0;
  }
}

export class TabsetUsecase extends Tabset {
  constructor(selector) {
    super(selector);
  }

  getImagesPath(panel) {
    return panel.refs.preview.querySelector('.usecase-preview__cover')
      .children[0].src;
  }

  getPanelRefs(panel) {
    panel.refs = {
      preview: panel.querySelector('.usecase-preview'),
      gallery: panel.querySelector('.usecase-gallery'),
      quarter: panel.querySelector('.usecase-quarter'),
      caption: panel.querySelector('.usecase-legend'),
    };
    return panel;
  }

  initTimelineWithParallax(panels) {
    const { entering, leaving } = panels;
    this.timeline
      .set(leaving.refs.preview, {
        y: Tabset.getTranslateYValue(leaving.refs.preview),
      })
      .set(leaving.refs.gallery, {
        y: Tabset.getTranslateYValue(leaving.refs.gallery),
      })
      .set(leaving.refs.quarter, {
        y: Tabset.getTranslateYValue(leaving.refs.quarter),
      })
      .set(entering.refs.preview, {
        y: Tabset.getTranslateYValue(leaving.refs.preview),
      })
      .set(entering.refs.gallery, {
        y: Tabset.getTranslateYValue(leaving.refs.gallery),
      })
      .set(entering.refs.quarter, {
        y: Tabset.getTranslateYValue(leaving.refs.quarter),
      });
  }

  setTimelineForDesktop(panels, direction) {
    const { entering, leaving } = panels;
    this.timeline
      .fromTo(leaving, 0.35, { alpha: 1 }, { alpha: 0 })
      .fromTo(
        leaving.refs.preview,
        0.35,
        { x: 0, alpha: 1 },
        { x: 60 * this.direction, alpha: 0 },
        '-=0.35',
      )
      .fromTo(
        leaving.refs.gallery,
        0.35,
        { x: 0, alpha: 1 },
        { x: 45 * this.direction, alpha: 0 },
        '-=0.35',
      )
      .fromTo(
        leaving.refs.quarter,
        0.35,
        { x: 0, alpha: 1 },
        { x: 30 * this.direction, alpha: 0 },
        '-=0.35',
      )
      .call(() => {
        leaving.classList.remove('active');
        leaving.style.zIndex = null;
        entering.classList.add('active');
        entering.style.zIndex = 0;
      })
      .fromTo(entering, 0.35, { alpha: 0 }, { alpha: 1 })
      .fromTo(
        entering.refs.preview,
        0.35,
        { x: -60 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.35',
      )
      .fromTo(
        entering.refs.gallery,
        0.35,
        { x: -45 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.35',
      )
      .fromTo(
        entering.refs.quarter,
        0.35,
        { x: -30 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.35',
      )
      .call(() => {
        this.refs.nav.classList.remove(direction);
        this.isAnimating = false;
      });
  }

  setTimelineForMobile(panels, direction) {
    const { entering, leaving } = panels;
    this.timeline
      .fromTo(leaving, 0.25, { alpha: 1 }, { alpha: 0 })
      .fromTo(
        leaving.refs.gallery,
        0.25,
        { x: 0, alpha: 1 },
        { x: 30 * this.direction, alpha: 0 },
        '-=0.25',
      )
      .fromTo(
        leaving.refs.caption,
        0.25,
        { x: 0, alpha: 1 },
        { x: 22 * this.direction, alpha: 0 },
        '-=0.25',
      )
      .fromTo(
        leaving.refs.preview,
        0.25,
        { x: 0, alpha: 1 },
        { x: 15 * this.direction, alpha: 0 },
        '-=0.25',
      )
      .call(() => {
        leaving.classList.remove('active');
        leaving.style.zIndex = null;
        entering.classList.add('active');
        entering.style.zIndex = 0;
      })
      .fromTo(entering, 0.25, { alpha: 0 }, { alpha: 1 })
      .fromTo(
        entering.refs.gallery,
        0.25,
        { x: -30 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.25',
      )
      .fromTo(
        entering.refs.caption,
        0.25,
        { x: -22 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.25',
      )
      .fromTo(
        entering.refs.preview,
        0.25,
        { x: -15 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.25',
      )
      .call(() => {
        this.refs.nav.classList.remove(direction);
        this.isAnimating = false;
      });
  }
}

export class TabsetPros extends Tabset {
  constructor(selector) {
    super(selector);
  }

  getImagesPath(panel) {
    return panel.refs.image.children[0].src;
  }

  getPanelRefs(panel) {
    panel.refs = {
      caption: panel.querySelector('.tabset-caption'),
      image: panel.querySelector('.tabset-image'),
      pattern: panel.querySelector('.tabset-pattern'),
      quarter: panel.querySelector('.tabset-graphic'),
    };
    return panel;
  }

  initTimelineWithParallax(panels) {
    const { entering, leaving } = panels;
    this.timeline
      .set(leaving.refs.image, {
        y: Tabset.getTranslateYValue(leaving.refs.image),
      })
      .set(leaving.refs.quarter, {
        y: Tabset.getTranslateYValue(leaving.refs.quarter),
      })
      .set(leaving.refs.caption, {
        y: Tabset.getTranslateYValue(leaving.refs.caption),
      })
      .set(entering.refs.image, {
        y: Tabset.getTranslateYValue(leaving.refs.image),
      })
      .set(entering.refs.quarter, {
        y: Tabset.getTranslateYValue(leaving.refs.quarter),
      })
      .set(entering.refs.caption, {
        y: Tabset.getTranslateYValue(leaving.refs.caption),
      });

    if (leaving.refs.pattern) {
      this.timeline.set(leaving.refs.pattern, {
        y: Tabset.getTranslateYValue(leaving.refs.pattern),
      });
    }
    if (entering.refs.pattern) {
      this.timeline.set(entering.refs.pattern, {
        y: Tabset.getTranslateYValue(leaving.refs.pattern),
      });
    }
  }

  setTimelineForDesktop(panels, direction) {
    const { entering, leaving } = panels;
    this.timeline
      .fromTo(leaving, 0.35, { alpha: 1 }, { alpha: 0 })
      .fromTo(
        leaving.refs.image,
        0.35,
        { x: 0, alpha: 1 },
        { x: 60 * this.direction, alpha: 0 },
        '-=0.35',
      );

    if (leaving.refs.pattern) {
      this.timeline.fromTo(
        leaving.refs.pattern,
        0.35,
        { x: 0, alpha: 1 },
        { x: 60 * this.direction, alpha: 0 },
        '-=0.35',
      );
    }

    this.timeline
      .fromTo(
        leaving.refs.caption,
        0.35,
        { x: 0, alpha: 1 },
        { x: 45 * this.direction, alpha: 0 },
        '-=0.35',
      )
      .fromTo(
        leaving.refs.quarter,
        0.35,
        { x: 0, alpha: 1 },
        { x: 30 * this.direction, alpha: 0 },
        '-=0.35',
      )
      .call(() => {
        leaving.classList.remove('active');
        leaving.style.zIndex = null;
        entering.classList.add('active');
        entering.style.zIndex = 0;
      })
      .fromTo(entering, 0.35, { alpha: 0 }, { alpha: 1 })
      .fromTo(
        entering.refs.image,
        0.35,
        { x: -60 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.35',
      );

    if (entering.refs.pattern) {
      this.timeline.fromTo(
        entering.refs.pattern,
        0.35,
        { x: -60 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.35',
      );
    }
    this.timeline
      .fromTo(
        entering.refs.caption,
        0.35,
        { x: -45 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.35',
      )
      .fromTo(
        entering.refs.quarter,
        0.35,
        { x: -30 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.35',
      )
      .call(() => {
        this.refs.nav.classList.remove(direction);
        this.isAnimating = false;
      });
  }

  setTimelineForMobile(panels, direction) {
    const { entering, leaving } = panels;
    this.timeline
      .fromTo(leaving, 0.25, { alpha: 1 }, { alpha: 0 })
      .fromTo(
        leaving.refs.image,
        0.25,
        { x: 0, alpha: 1 },
        { x: 30 * this.direction, alpha: 0 },
        '-=0.25',
      );

    if (leaving.refs.pattern) {
      this.timeline.fromTo(
        leaving.refs.pattern,
        0.25,
        { x: 0, alpha: 1 },
        { x: 30 * this.direction, alpha: 0 },
        '-=0.25',
      );
    }

    this.timeline
      .fromTo(
        leaving.refs.caption,
        0.25,
        { x: 0, alpha: 1 },
        { x: 22 * this.direction, alpha: 0 },
        '-=0.25',
      )
      .fromTo(
        leaving.refs.quarter,
        0.25,
        { x: 0, alpha: 1 },
        { x: 15 * this.direction, alpha: 0 },
        '-=0.25',
      )
      .call(() => {
        leaving.classList.remove('active');
        leaving.style.zIndex = null;
        entering.classList.add('active');
        entering.style.zIndex = 0;
      })
      .fromTo(entering, 0.25, { alpha: 0 }, { alpha: 1 })
      .fromTo(
        entering.refs.image,
        0.25,
        { x: -30 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.25',
      );

    if (entering.refs.pattern) {
      this.timeline.fromTo(
        entering.refs.pattern,
        0.25,
        { x: -30 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.25',
      );
    }
    this.timeline
      .fromTo(
        entering.refs.caption,
        0.25,
        { x: -22 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.25',
      )
      .fromTo(
        entering.refs.quarter,
        0.25,
        { x: -15 * this.direction, alpha: 0 },
        { x: 0, alpha: 1 },
        '-=0.25',
      )
      .call(() => {
        this.refs.nav.classList.remove(direction);
        this.isAnimating = false;
      });
  }
}
