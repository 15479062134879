import React, { MutableRefObject, RefObject } from 'react';
import { H2 } from 'primitives/appTitle';
import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import Highlights, {
  getHLMessages,
  Highlight,
  HLNumber,
} from '../../components/highlights';
import AppSection from 'primitives/appSection';
import { FrontHomeMessages } from './homePageTranslations';
import { ShText } from '@shoootin/translations';

export const getHLMessagesHome = (panelNumber: HLNumber) => {
  return getHLMessages(panelNumber, FrontHomeMessages);
};

export const highlights: Highlight[] = [
  {
    title: getHLMessagesHome(1).title,
    p1: getHLMessagesHome(1).p1,
    svg: true,
    svgColor: {
      stopColor1: '#7fbb6e',
      stopColor2: '#5472FF',
    },
  },
  {
    title: getHLMessagesHome(2).title,
    p1: getHLMessagesHome(2).p1,
    svg: true,
    svgColor: {
      stopColor1: '#5472FF',
      stopColor2: '#9f4ecf',
    },
  },
  {
    title: getHLMessagesHome(3).title,
    p1: getHLMessagesHome(3).p1,
    svg: true,
    svgColor: {
      stopColor1: '#5472FF',
      stopColor2: '#18b0b6',
    },
  },
];

export const HomeHighlights = () => {
  return (
    <AppSection
      id="highlights"
      header={
        <div>
          <H2>
            <ShText message={FrontHomeMessages.highlightsTitle} />
          </H2>
          <p>
            <ShText message={FrontHomeMessages.highlightsDescription} />
          </p>
          <p>
            <AppInternalLink to={AppInternalPaths.about} className="link">
              <ShText message={FrontHomeMessages.highlightsMore} />
            </AppInternalLink>
          </p>
        </div>
      }
    >
      <Highlights highlights={highlights} />
    </AppSection>
  );
};

export default HomeHighlights;
