import React from 'react';

const ChevronSelectSvg = (props: any) => (
  <svg
    className="chevron-select"
    viewBox="0 0 25 25"
    height={25}
    width={25}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="22.75,7.25 12.5,17.75 2.25,7.25 " />
  </svg>
);

export default ChevronSelectSvg;
