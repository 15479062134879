import { graphql, useStaticQuery } from 'gatsby';
import { HeroSliderImagesQuery } from 'appGraphQLTypes';
import { AppGatsbyImageFluid } from '../../../primitives/appImage';
import { ShLocale } from '@shoootin/config';

const useHeroSliderImages = (locale: ShLocale): AppGatsbyImageFluid[] => {
  const data = useStaticQuery<HeroSliderImagesQuery>(
    graphql`
      query HeroSliderImages {
        allFile(
          sort: { fields: name, order: ASC }
          filter: { relativePath: { glob: "home_hero-*" } }
        ) {
          edges {
            node {
              ...AppFluidHeroImageFragment
            }
          }
        }
        usFile: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativePath: { glob: "home_hero_US-*" } }
        ) {
          edges {
            node {
              ...AppFluidHeroImageFragment
            }
          }
        }
      }
    `,
  );
  const files = locale === 'en_US' ? data.usFile : data.allFile;

  const fluids = files.edges.map((edge) => edge!.node!.childImageSharp!.fluid!);

  return fluids;
};

export default useHeroSliderImages;
