import React from 'react';

const PlanPathSvg = (props: any) => (
  <svg
    className="plan-path"
    width={1039}
    height={140}
    viewBox="0 0 1039 140"
    {...props}
  >
    <linearGradient
      id="rainbow-path"
      gradientUnits="userSpaceOnUse"
      x1={520}
      y1={0}
      x2={520}
      y2={210}
    >
      <stop offset={0} stopColor="#EB6E68" />
      <stop offset={0.32} stopColor="#C576F7" />
      <stop offset={0.7} stopColor="#FFBACF" />
      <stop offset={0.88} stopColor="#F1E688" />
      <stop offset={1} stopColor="#FFD59F" />
    </linearGradient>
    <path
      d="M-197.988 35.624c661.44 306.013 585.147-223.513 1444.477 83.057"
      fill="none"
      stroke="url(#rainbow-path)"
      strokeWidth={6}
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeDasharray="14 16"
    />
    <g fill="#FFFFFF" strokeWidth={5}>
      <circle cx={0} cy={113} r={14} stroke="#F6ACDD" />
      <circle cx={383} cy={97} r={14} stroke="#D287F7" />
      <circle cx={705} cy={24} r={14} stroke="#E56E7B" />
      <circle cx={1039} cy={58} r={14} stroke="#C770E2" />
    </g>
  </svg>
);

export default PlanPathSvg;
