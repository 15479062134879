/*
<div class="slider-nav">
    <a class="slider-nav__arrow prev" href="">
        @graphics.picto.chevronPrev()
    </a>
    <a class="slider-nav__arrow next" href="">
        @graphics.picto.chevronNext()
    </a>
</div>
 */

import React from 'react';

import ChevronPrevSvg from '../svg/picto/chevronPrevSvg';
import ChevronNextSvg from '../svg/picto/chevronNextSvg';

type SliderNav = { prev?: () => void; next?: () => void };

const SliderNav = ({ prev, next }: SliderNav) => (
  <div className="slider-nav">
    <a className="slider-nav__arrow prev" onClick={prev}>
      <ChevronPrevSvg />
    </a>
    <a className="slider-nav__arrow next" onClick={next}>
      <ChevronNextSvg />
    </a>
  </div>
);

export default SliderNav;
