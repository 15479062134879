import * as React from 'react';
import Tags, { TagsProps } from './tags';
import { PrestationName } from '../appConstants';
import { FrontCommonMessages } from '../appCommonTranslations';
import { AppInternalPaths } from '../primitives/appLink';

const ServiceTags = ({
  services,
  modifier,
}: {
  services: PrestationName[];
  modifier?: TagsProps['modifier'];
}) => (
  <Tags
    tags={services.map(service => ({
      label: FrontCommonMessages[service],
      href: AppInternalPaths.service(service),
    }))}
    modifier={modifier}
  />
);

export default ServiceTags;
