import React from 'react';
import { useAsync } from 'react-async-hook';
import { getCountryRestrictions } from '../../appConstants';
import { useGoogleMapsLoaded } from './appMapLoader';
import { ShCountry } from '@shoootin/config';

const getCurrentPositionAsync = (): Promise<Position> => {
  const options = {
    enableHighAccuracy: false,
    timeout: 1000,
    maximumAge: 60000,
  };
  return new Promise<Position>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

export type PlacePrediction = google.maps.places.AutocompletePrediction;

const getPlacePredictions = async (
  text: string,
  country: ShCountry,
): Promise<PlacePrediction[]> => {
  const service = new google.maps.places.AutocompleteService();
  // TODO try to search near current user position? but this seems to delay the search
  //const position = await getCurrentPositionAsync();
  return new Promise((resolve, reject) => {
    service.getPlacePredictions(
      {
        input: text,
       // We want to test without geo restrictions
       // types: ['geocode'],
        componentRestrictions: {
          country: getCountryRestrictions(country),
        },

        /*
        ...(position && {
          location: new google.maps.LatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
          radius: 50000,
        }),
        */
      },
      (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          // console.debug('getPlacePredictions', result);
          resolve(result);
        } else {
          console.warn('getPlacePredictions', status);
          resolve([]);
        }
      },
    );
  });
};

export type PlaceDetail = google.maps.places.PlaceResult;

export const getPlaceDetails = async (
  placeId: string,
): Promise<PlaceDetail> => {
  const service = new google.maps.places.PlacesService(
    // We might not really conform to Google TOS here...
    // see https://stackoverflow.com/a/14345546/82609
    document.createElement('div'),
  );
  // TODO try to search near current user position? but this seems to delay the search
  //const position = await getCurrentPositionAsync();
  return new Promise((resolve, reject) => {
    service.getDetails({ placeId }, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        console.debug('getPlacePredictions', result);
        resolve(result);
      } else {
        console.warn('getPlacePredictions', status);
        reject(new Error('getPlaceDetails status=' + status));
      }
    });
  });
};

type UseGooglePlacesParams = {
  text: string;
  country: ShCountry;
};
export const useGooglePlacesPredictions = (
  params: UseGooglePlacesParams,
): PlacePrediction[] => {
  const mapsLoaded = useGoogleMapsLoaded();
  const predictionsAsync = useAsync(
    async () => {
      if (mapsLoaded && params.text.trim().length > 0) {
        return getPlacePredictions(params.text, params.country);
      } else {
        return [];
      }
    },
    [params.text, params.country, mapsLoaded],
    {
      setLoading: state => ({ ...state, loading: true }),
    },
  );
  return predictionsAsync.result || [];
};
