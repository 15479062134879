import React, { useEffect, useState } from 'react';

import AppLinkBar from '../../primitives/appLinkBar';
import classnames from 'classnames';
// @ts-ignore
import { TabsetUsecase } from 'legacyJs/tabset';

import { H1, H3, H5 } from 'primitives/appTitle';
import CrosshairSvg from 'components/svg/picto/crosshairSvg';
import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import PatternSvg from 'components/svg/patternSvg';
import QuarterSvg from 'components/svg/quarterSvg';
import { ShText, useShIntlLocale } from '@shoootin/translations';
import { FrontCommonMessages } from '../../appCommonTranslations';
import { ProfessionGalleryModal } from '../../components/gallery/galleryModal';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';

import AppSlider from '../../primitives/appSlider';
import useClientLogoImages from 'queries/useClientLogoImages';
import useHomeProfessionGalleries from 'page/home/queries/useHomeProfessionGalleries';
import useIntersectionObserverInitializer from '../../hooks/useIntersectionObserverInitializer';
import ServiceTags from '../../components/serviceTags';
import {
  ProfessionShootingType,
  ProfessionData,
  getMainProfessionUsecaseOfLocale,
} from '../../appConstants';
import useUsecaseHeaderImages from '../../queries/useUsecaseHeaderImages';
import { forceImageMaxWidth } from '../../appGatsbyImageUtils';
import { getProfessionMessage } from '../../appMessageDefinitions';
import { UsecaseData } from '../../appConstantsUsecase';
import { ShLocale } from '@shoootin/config';

const HomeTabsetProfession = ({
  profession,
  classes,
  onGalleryClick,
  images,
}: {
  profession: ProfessionShootingType;
  classes: string;
  onGalleryClick: (index: number) => void;
  images: AppGatsbyImageFluid[];
}) => {
  const { mainUsecases, logos } = ProfessionData[profession];

  const locale = useShIntlLocale();
  const localeUsecase = getMainProfessionUsecaseOfLocale(mainUsecases, locale);
  const usecaseHeaderImage = forceImageMaxWidth(
    useUsecaseHeaderImages()[localeUsecase],
    500,
  );

  const logosByLocale = logos[locale as ShLocale] || logos.defaultLogos;

  const logoImages = useClientLogoImages(logosByLocale);

  return (
    <div className={classnames(' home-usecase', classes)} key={profession}>
      <div className="usecase-preview" _data-parallax="0.2">
        <div className="usecase-preview__cover">
          <AppGatsbyImage
            fluid={usecaseHeaderImage}
            css={{ backgroundColor: 'grey' }}
          />
          <div className="usecase-preview__legend">
            <H3>{UsecaseData[localeUsecase].label}</H3>
            <AppInternalLink
              to={AppInternalPaths.usecase(localeUsecase)}
              className="invert"
            >
              <ShText message={FrontCommonMessages.seeUsecase} />
            </AppInternalLink>
          </div>
          <div className="usecase-preview__decoration">
            <CrosshairSvg />
            <CrosshairSvg />
            <CrosshairSvg />
          </div>
        </div>
        <ServiceTags services={UsecaseData[localeUsecase].services} />
      </div>

      <div className="usecase-gallery" _data-parallax="0.4">
        <AppSlider
          className="gallery-slider"
          images={images}
          onImageClick={(index) => onGalleryClick(index)}
        />
        <PatternSvg />
      </div>

      <div className="usecase-quarter" _data-parallax="0.7">
        <QuarterSvg mode="rainbow" />
      </div>
      {logoImages.length > 0 && (
        <div className="usecase-references" _data-parallax="0.8">
          <H5>
            <ShText message={FrontCommonMessages.professionTrustUs} />
          </H5>
          <div className="usecase-references__logos">
            {logoImages.map((logoImage, i) => (
              <AppGatsbyImage
                key={i}
                fluid={logoImage}
                style={{ display: 'inline-block' }}
              />
            ))}
          </div>
        </div>
      )}
      <div className="usecase-legend" _data-parallax="0.7">
        <H1>
          <ShText message={getProfessionMessage(profession, 'short')} />
        </H1>
        <p>
          <ShText
            message={getProfessionMessage(profession, 'shortDescription')}
          />
        </p>
        <p>
          <AppInternalLink to={AppInternalPaths.profession(profession)}>
            <ShText message={FrontCommonMessages.professionSeeMore} />
          </AppInternalLink>
        </p>
      </div>
    </div>
  );
};

const HomeTabsetProfessionTabSet = () => {
  const initializer = useIntersectionObserverInitializer();
  const [selectedProfession, setSelectedProfession] = useState<
    ProfessionShootingType
  >();

  const HomeProfessions: ProfessionShootingType[] = [
    'residentialRealEstate',
    'commercialRealEstate',
    'architect',
    'hotel',
    'restaurant',
    'retail',
  ];
  // optimize critical render path
  const homeProfessionsFiltered = initializer.isInitialized
    ? HomeProfessions
    : [HomeProfessions[0]];

  useEffect(() => {
    if (initializer.isInitialized) {
      console.debug('initializing home profession tabset');
      new TabsetUsecase('.tabset-usecase');
    }
  }, [initializer.isInitialized]);

  const locale = useShIntlLocale();
  const homeProfessionGalleries = useHomeProfessionGalleries(true, locale);

  return (
    <div ref={initializer.ref} className="tabset tabset-usecase">
      <AppLinkBar
        links={HomeProfessions.map((profession) => ({
          label: getProfessionMessage(profession, 'short'),
          href: AppInternalPaths.profession(profession),
        }))}
        modifier="tabset-nav"
      />
      <div className="tabset-content">
        {homeProfessionsFiltered.map((profession, i) => (
          <HomeTabsetProfession
            key={i}
            profession={profession}
            images={homeProfessionGalleries[profession]}
            classes={classnames('tabset-panel', { active: i === 0 })}
            onGalleryClick={(index) => {
              // TODO gallery should init on current clicked image index
              setSelectedProfession(profession);
            }}
          />
        ))}
      </div>
      <ProfessionGalleryModal
        selectedProfession={selectedProfession}
        onClose={() => setSelectedProfession(undefined)}
      />
    </div>
  );
};

export default HomeTabsetProfessionTabSet;
