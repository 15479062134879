import React from 'react';

const FullscreenSvg = (props: any) => (
  <svg
    className="slider-pause"
    height={16}
    width={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <polyline points="2.5,5 2.5,2.5 5,2.5 " />
    <polyline points="5,13.5 2.5,13.5 2.5,11 " />
    <polyline points="13.5,11 13.5,13.5 11,13.5 " />
    <polyline points="11,2.5 13.5,2.5 13.5,5 " />
    <line x1={10} y1={10} x2={13.5} y2={13.5} />
    <line x1={2.5} y1={2.5} x2={6} y2={6} />
    <line x1={6} y1={10} x2={2.5} y2={13.5} />
    <line x1={13.5} y1={2.5} x2={10} y2={6} />
  </svg>
);

export default FullscreenSvg;
