import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { AppGatsbyImageFluid } from '../../../primitives/appImage';
import { forceImageMaxWidth, getFluidImages } from 'appGatsbyImageUtils';
import { HomeProfessionSecondaryImagesQuery } from 'appGraphQLTypes';
import useProfessionFirstImages from 'queries/useProfessionFirstImages';
import {
  ProfessionGalleryImages,
  ProfessionImagesEmpty,
  Professions,
  ProfessionShootingType,
  ProfessionShootingTypeLocalized,
} from '../../../appConstants';
import useProfessionLastImages from '../../../queries/useProfessionLastImages';
import { ShLocale, ShLocaleConfigs } from '@shoootin/config';
import { mapValues } from 'lodash';

type HomeProfessionGalleryImages = {
  [P in ProfessionShootingType]: AppGatsbyImageFluid[];
};

const useHomeProfessionSecondaryImages = (
  locale: ShLocale,
): HomeProfessionGalleryImages => {
  const data = useStaticQuery<HomeProfessionSecondaryImagesQuery>(graphql`
    # we don't want traced svgs for next slider images (it's expensive to inline many images and grows bundle size)
    fragment HomeProfessionSecondaryImage on FileConnection {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(
              maxWidth: 1681
              quality: 90
              # not too many breakpoints because there are many images
              # each breakpoint has a cost on the js/html size
              srcSetBreakpoints: [500, 681, 1681]
            ) {
              # Only first images of each gallery has traced svgs
              # other images of each gallery only has less values
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }

    query HomeProfessionSecondaryImages {
      residentialRealEstate: allFile(
        sort: { fields: name, order: ASC }
        limit: 9
        skip: 1
        filter: { relativePath: { glob: "profession_residential-gallery-*" } }
      ) {
        ...HomeProfessionSecondaryImage
      }
      residentialRealEstate_US: allFile(
        sort: { fields: name, order: ASC }
        limit: 9
        skip: 1
        filter: {
          relativePath: { glob: "profession_residential_us-gallery-*" }
        }
      ) {
        ...HomeProfessionSecondaryImage
      }
      commercialRealEstate: allFile(
        sort: { fields: name, order: ASC }
        limit: 9
        skip: 1
        filter: { relativePath: { glob: "profession_commercial-gallery-*" } }
      ) {
        ...HomeProfessionSecondaryImage
      }
      commercialRealEstate_US: allFile(
        sort: { fields: name, order: ASC }
        limit: 9
        skip: 1
        filter: { relativePath: { glob: "profession_commercial_us-gallery-*" } }
      ) {
        ...HomeProfessionSecondaryImage
      }
      architect: allFile(
        sort: { fields: name, order: ASC }
        limit: 9
        skip: 1
        filter: { relativePath: { glob: "profession_archideco-gallery-*" } }
      ) {
        ...HomeProfessionSecondaryImage
      }
      hotel: allFile(
        sort: { fields: name, order: ASC }
        limit: 9
        skip: 1
        filter: { relativePath: { glob: "profession_hotel-gallery-*" } }
      ) {
        ...HomeProfessionSecondaryImage
      }
      retail: allFile(
        sort: { fields: name, order: ASC }
        limit: 9
        skip: 1
        filter: { relativePath: { glob: "profession_retail-gallery-*" } }
      ) {
        ...HomeProfessionSecondaryImage
      }
      restaurant: allFile(
        sort: { fields: name, order: ASC }
        limit: 9
        skip: 1
        filter: { relativePath: { glob: "profession_restaurant-gallery-*" } }
      ) {
        ...HomeProfessionSecondaryImage
      }
    }
  `);
  return useMemo(() => {
    const images: ProfessionGalleryImages = { ...ProfessionImagesEmpty };

    Professions.forEach((professionKey) => {
      const localizedKey = locale
        ? (`${professionKey}_${ShLocaleConfigs[locale].country}` as ProfessionShootingTypeLocalized)
        : professionKey;

      images[professionKey] = data[localizedKey] ?? data[professionKey];
    });

    return mapValues(images, (_, key) => getFluidImages(images, key));
  }, [data, locale]);
};

// First gallery images and secondary gallery images do not use the same gatsby-image output
// We need to reduce the output size for the secondary gallery images because there are many images in the sliders
// most of them won't even be displayed so we try to optimize them a little bit less

const useHomeProfessionGalleries = (
  forceMaxWidth: boolean,
  locale: ShLocale,
): HomeProfessionGalleryImages => {
  const firstImages = useProfessionFirstImages(locale);
  const secondaryGalleryImages = useHomeProfessionSecondaryImages(locale);
  const lastGalleryImages = useProfessionLastImages(locale);

  const finalGalleryImages = mapValues(
    secondaryGalleryImages,
    (images, key) => {
      const professionKey = key as ProfessionShootingType;
      const firstImage = firstImages[professionKey];
      const secondaryImages = secondaryGalleryImages[professionKey];
      const lastImages = lastGalleryImages[professionKey];
      return [
        forceMaxWidth ? forceImageMaxWidth(firstImage, 681) : firstImage,
        ...(forceMaxWidth
          ? secondaryImages.map((image) => forceImageMaxWidth(image, 681))
          : secondaryImages),
        ...(forceMaxWidth
          ? lastImages.map((image) => forceImageMaxWidth(image, 681))
          : lastImages),
      ];
    },
  );

  return finalGalleryImages;
};

export default useHomeProfessionGalleries;
