import React, { useEffect, useRef, useState } from 'react';
import HeroSlide from './heroSlide';
import CrosshairSvg from '../svg/picto/crosshairSvg';
import { range } from 'lodash';
import ChevronSelectSvg from '../svg/picto/chevronSelectSvg';
import ChevronDownCircleSvg from '../svg/icon/chevronDownCircleSvg';
import HeroNavSvg from '../svg/icon/heroNavSvg';

// @ts-ignore
import initLegacyHeroSlider from './hero-slider';
import {
  ShText,
  useShIntlLocale,
  useShTranslate,
} from '@shoootin/translations';
import { FrontHomeMessages } from '../../page/home/homePageTranslations';
import { FrontCommonMessages } from '../../appCommonTranslations';
import { getFrontMessage } from '../../appMessageDefinitions';
import { scrollToId } from '../../utils/domUtils';
import { AppButton } from '../../primitives/appButton';

import {
  getPlaceDetails,
  PlacePrediction,
} from '../../primitives/maps/appMapHooks';
import { useNavigate } from '../../primitives/appLink';
import AppGooglePlaceAutocompleteInput from 'primitives/maps/appGooglePlaceAutocompleteInput';
import { placeToOrderAddress } from '../../page/order/utils/orderPageUtils';
import { useFrontCountry } from '../../layout/frontContext';
import useHeroSliderImages from 'page/home/queries/useHeroSliderImages';
import useRequestIdleCallbackInitializer from '../../hooks/useRequestIdleCallbackInitializer';
import { EmptySelectedOffers } from '../../page/order/context/orderPageContextState';

const HeroOrderForm = () => {
  const translate = useShTranslate();
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [place, setPlace] = useState<PlacePrediction>();
  const country = useFrontCountry();
  return (
    <div className="hero-control field-control large">
      <AppGooglePlaceAutocompleteInput
        country={country}
        value={text}
        onChange={(text) => {
          setText(text);
          setPlace(undefined);
        }}
        onPlaceSelected={(place) => {
          setPlace(place);
        }}
        wrapDropdown={(dropdown) => (
          <div css={{ borderRadius: 5, overflow: 'hidden', marginTop: 5 }}>
            {dropdown}
          </div>
        )}
        renderInput={(ref, { placeholderMessage, placeholder, ...props }) => {
          const placeholderText = placeholderMessage
            ? translate(placeholderMessage)
            : placeholder;
          return (
            <input
              ref={ref}
              aria-label={placeholderText}
              {...props}
              placeholder={placeholderText}
            />
          );
        }}
      />
      <AppButton
        modifier="large"
        disabled={!place}
        onClick={async (e) => {
          e.preventDefault();
          const placeDetails = await getPlaceDetails(place!.place_id);
          console.debug('placeDetails', placeDetails);
          navigate.toOrder({
            address: placeToOrderAddress(placeDetails),
            country,
            selectedOffers: EmptySelectedOffers,
          });
        }}
      >
        <ShText message={getFrontMessage('common_actions_validate')} />
      </AppButton>
    </div>
  );
};

const HeroSlider = () => {
  const locale = useShIntlLocale();
  const fluidImages = useHeroSliderImages(locale);

  // Optimize SSR output + TTI
  const initializer = useRequestIdleCallbackInitializer();
  const fluidImagesFiltered = initializer.isInitialized
    ? fluidImages
    : [fluidImages[0]];

  const heroSliderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (initializer.isInitialized) {
      const shutdownHeroSlider = initLegacyHeroSlider(heroSliderRef.current);
      return shutdownHeroSlider;
    }
  }, [initializer.isInitialized]);

  return (
    <div className="hero hero-slider" ref={heroSliderRef}>
      <div className="hero-slider__wrapper">
        {fluidImagesFiltered.map((fluidImage, i) => (
          <HeroSlide key={i} first={i === 0} fluidImage={fluidImage} />
        ))}
      </div>
      <div className="container">
        <form className="hero-form">
          <div className="hero-slider__decoration">
            {range(0, 4).map((i) => (
              <CrosshairSvg key={i} />
            ))}
          </div>
          <h1 className="hero-title title invert">
            <ShText message={FrontHomeMessages.heroOrder} />
            <div className="hero-select">
              <div className="hero-select__wrapper">
                <div className="hero-select__mask">
                  <div className="hero-select__toggle photo active">
                    <span className="hero-select__choice photo active">
                      <ShText message={FrontHomeMessages.heroPhoto} />{' '}
                      <ChevronSelectSvg />
                    </span>
                    <span className="hero-select__choice video">
                      <ShText message={FrontHomeMessages.heroVideo} />{' '}
                      <ChevronSelectSvg />
                    </span>
                    <span className="hero-select__choice matterport">
                      <ShText message={FrontHomeMessages.heroMatterport} />{' '}
                      <ChevronSelectSvg />
                    </span>
                    <span className="hero-select__choice drone">
                      <ShText message={FrontHomeMessages.heroDrone} />{' '}
                      <ChevronSelectSvg />
                    </span>
                  </div>
                </div>
                <div className="hero-select__menu">
                  <a href="#photo" className="hero-select__item photo active">
                    <ShText message={FrontCommonMessages.photo} />
                  </a>
                  <a href="#video" className="hero-select__item video">
                    <ShText message={FrontCommonMessages.video} />
                  </a>
                  <a
                    href="#matterport"
                    className="hero-select__item matterport"
                  >
                    <ShText message={FrontCommonMessages.scan} />
                  </a>
                  <a href="#drone" className="hero-select__item drone">
                    <ShText message={FrontCommonMessages.drone} />
                  </a>
                </div>
              </div>
            </div>
            <div className="hero-select__mask">
              <div className="hero-select__tag active">
                <span className="hero-select__price active">
                  <ShText message={FrontHomeMessages.heroPhotoPrice} />
                </span>
                <span className="hero-select__price">
                  <ShText message={FrontHomeMessages.heroVideoPrice} />
                </span>
                <span className="hero-select__price">
                  <ShText message={FrontHomeMessages.heroMatterportPrice} />
                </span>
                <span className="hero-select__price">
                  <ShText message={FrontHomeMessages.heroDronePrice} />
                </span>
              </div>
            </div>
          </h1>
          <HeroOrderForm />
          <div className="hero-caption">
            <p className="invert">
              <ShText message={FrontHomeMessages.heroP1} />
            </p>
            <a className="hero-link link invert" href="">
              <ShText message={FrontHomeMessages.heroMore} />
            </a>
          </div>
        </form>
      </div>

      <div className="hero-slider__nav">
        <HeroNavSvg />
      </div>
      <a
        className="hero-scroll"
        css={{ cursor: 'pointer' }}
        // href="#highlights"
        onClick={() => {
          scrollToId('#highlights');
        }}
      >
        <ChevronDownCircleSvg />
      </a>
      <div className="hero-slider__progress" />
    </div>
  );
};

export default HeroSlider;
