import React from 'react';

import { H4 } from '../primitives/appTitle';
import FirstLetterSvg from './svg/alphabet/firstLetterSvg';
import {
  ShIntlMessageDefinition,
  ShText,
  useShTranslate,
} from '@shoootin/translations';

export type Highlight = {
  title: ShIntlMessageDefinition;
  p1: ShIntlMessageDefinition;
  svg: boolean;
  svgColor: {
    stopColor1: string;
    stopColor2: string;
  };
};
const HLNumbers = [1, 2, 3];
export type HLNumber = typeof HLNumbers[number];

export const getHLMessages = (
  panelNumber: HLNumber,
  messages: Record<string, ShIntlMessageDefinition>,
): {
  title: ShIntlMessageDefinition;
  p1: ShIntlMessageDefinition;
} => ({
  title: messages[`highlightsPanels_${panelNumber}_title`],
  p1: messages[`highlightsPanels_${panelNumber}_p1`],
});

const Highlights = ({ highlights }: { highlights: Highlight[] }) => {
  const translate = useShTranslate();
  return (
    <div className="empty">
      <div className="highlights">
        {highlights.map((highlight, i) => (
          <div key={i} className="highlight">
            {highlight.svg && (
              <FirstLetterSvg
                forText={translate(highlight.title)}
                width={230}
                stopColor1={highlight.svgColor.stopColor1}
                stopColor2={highlight.svgColor.stopColor2}
              />
            )}
            <H4>
              <ShText message={highlight.title} />
            </H4>
            <p>
              <ShText message={highlight.p1} />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Highlights;
