import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { range } from 'lodash';

const SliderPagination = ({ count }: { count: number }) => (
  <ul className="slider-pagination">
    {range(0, count).map(i => (
      <li key={i} className="slider-pagination__bullet" />
    ))}
  </ul>
);

export default SliderPagination;
