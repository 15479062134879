import React, { ReactNode } from 'react';
import { AppPlacesDropdownOptions } from 'primitives/maps/appMapElements';
import { getFrontMessage } from 'appMessageDefinitions';
import { useGooglePlacesPredictions } from 'primitives/maps/appMapHooks';
import { useFocus } from 'use-events';
import { AppDropdownPopper } from 'primitives/appPoppers';
import { AppInputText, AppInputTextProps } from '../input/appInputText';
import { mergeHandlers } from '../../utils/mergeHandlers';
import { Omit } from 'utility-types';

import { ShCountry } from '@shoootin/config';

const AppGooglePlaceAutocompleteInput = ({
  value,
  onChange,
  country,
  onPlaceSelected,
  // Needed to add some padding on homepage
  wrapDropdown = (children) => children,
  // Needed to render a simple input without field controls on homepage
  renderInput = (ref, props) => <AppInputText inputRef={ref} {...props} />,
  ...inputTextProps
}: {
  value: string;
  onChange: (text: string) => void; // because need to set text without event
  country: ShCountry;
  onPlaceSelected: (place: google.maps.places.AutocompletePrediction) => void;
  wrapDropdown?: (children: ReactNode) => ReactNode;
  renderInput?: (
    refHandler: (ref: HTMLElement | null) => void,
    props: AppInputTextProps,
  ) => ReactNode;
} & Omit<AppInputTextProps, 'value' | 'onChange'>) => {
  const [isFocused, bindFocus] = useFocus();
  const places = useGooglePlacesPredictions({ text: value, country });
  const visible = isFocused && value.trim().length > 0 && places.length > 0;

  return (
    <AppDropdownPopper
      opened={visible}
      target={(ref) => {
        return renderInput(ref, {
          placeholderMessage: getFrontMessage(
            'form_address_address_PlaceHolder',
          ),
          ...inputTextProps,
          value,
          onChange: (e) => onChange(e.target.value),
          onFocus: mergeHandlers([inputTextProps.onFocus, bindFocus.onFocus]),
          onBlur: mergeHandlers([inputTextProps.onBlur, bindFocus.onBlur]),
        });
      }}
      content={() => {
        return wrapDropdown(
          <AppPlacesDropdownOptions
            places={places}
            onPlaceSelected={(place) => {
              if (value !== place.description) {
                onChange(place.description);
              }
              onPlaceSelected(place);
            }}
          />,
        );
      }}
    />
  );
};

export default AppGooglePlaceAutocompleteInput;
