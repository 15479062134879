import React, { useRef } from 'react';
import { H4 } from '../../primitives/appTitle';
import './frontHome.less';
import PlanPathSvg from '../../components/svg/planPathSvg';

import { ShIntlMessageDefinition, ShText } from '@shoootin/translations';
import { FrontHomeMessages } from './homePageTranslations';
import useIntersectionObserverInitializer from '../../hooks/useIntersectionObserverInitializer';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';
import useHowItWorksImages from 'page/home/queries//useHowItWorksImages';
import { ShCol, ShRow } from '@shoootin/components-web';
import { initLegacySlider } from '../../components/slider/imageSlider';
import classnames from 'classnames';

export interface Card {
  label: ShIntlMessageDefinition;
  text: ShIntlMessageDefinition;
  parallax: number;
}

const Cards: Card[] = [
  {
    label: FrontHomeMessages.howItWorksPanel1Title,
    text: FrontHomeMessages.howItWorksPanel1Description,
    parallax: 0.1,
  },
  {
    label: FrontHomeMessages.howItWorksPanel2Title,
    text: FrontHomeMessages.howItWorksPanel2Description,
    parallax: 0.9,
  },
  {
    label: FrontHomeMessages.howItWorksPanel3Title,
    text: FrontHomeMessages.howItWorksPanel3Description,
    parallax: 0.1,
  },
  {
    label: FrontHomeMessages.howItWorksPanel4Title,
    text: FrontHomeMessages.howItWorksPanel4Description,
    parallax: 0.9,
  },
];

const HomePageCardsTimeline = () => {
  const fluidImages = useHowItWorksImages();
  return (
    <ShRow className="home-plan__cards">
      <div className="home-plan__path">
        <PlanPathSvg />
      </div>
      {Cards.map((card, i) => (
        <ShCol key={i} xs={15} data-parallax={card.parallax}>
          <figure className="home-plan__card">
            <span className="home-plan__cover">
              <AppGatsbyImage
                fluid={fluidImages[i]}
                style={{ position: 'absolute' }}
              />
            </span>
            <figcaption className="home-plan__caption">
              <H4>
                <ShText message={card.label} />
              </H4>
              <p>
                <ShText message={card.text} />
              </p>
            </figcaption>
          </figure>
        </ShCol>
      ))}
    </ShRow>
  );
};

const getSlides = (cards: Card[], images: AppGatsbyImageFluid[]) => {
  return cards.map((figure, index) => {
    return { label: figure.label, text: figure.text, image: images[index] };
  });
};

const HomePageCardsSwipe = () => {
  const fluidImages = useHowItWorksImages();

  const slides = getSlides(Cards, fluidImages);

  const sliderRef = useRef<HTMLDivElement>(null);

  const initializer = useIntersectionObserverInitializer({
    callback: () => {
      initLegacySlider(sliderRef.current!);
      console.debug('initLegacySlider');
    },
  });

  return (
    <>
      {initializer.marker}
      <div className={classnames('slider', 'inline')} ref={sliderRef}>
        <div className="home-plan__cards swiper-container">
          <div
            className="swiper-wrapper"
            css={{
              display: 'flex',
            }}
          >
            {slides.map((card, i) => (
              <figure key={i} className="home-plan__card swiper-slide">
                <span className="home-plan__cover">
                  <AppGatsbyImage
                    fluid={card.image}
                    style={{ position: 'absolute' }}
                  />
                </span>
                <figcaption className="home-plan__caption">
                  <H4>
                    <ShText message={card.label} />
                  </H4>
                  <p>
                    <ShText message={card.text} />
                  </p>
                </figcaption>
              </figure>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const HomePageCards = () => {
  return (
    <>
      <HomePageCardsTimeline />
      <HomePageCardsSwipe />
    </>
  );
};

export default HomePageCards;
