import React from 'react';
import classnames from 'classnames';
import { ShIntlMessageDefinition, ShText } from '@shoootin/translations';
import { AppInternalLink, AppInternalPath } from '../primitives/appLink';

export type Tag = {
  label: ShIntlMessageDefinition;
  href: AppInternalPath;
};

export type TagsProps = { tags: Tag[]; modifier?: 'ghost' };

const Tags = ({ tags, modifier }: TagsProps) => (
  <ul className="tags">
    {tags.map((tag, i) => (
      <li key={i} className="tag">
        <AppInternalLink to={tag.href}>
          <button className={classnames('btn', ' float', ' light', modifier)}>
            <ShText message={tag.label} />
          </button>
        </AppInternalLink>
      </li>
    ))}
  </ul>
);

export default Tags;
