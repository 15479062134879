import React from 'react';
import { ShSvgWorldMap, ShWorldMapCircles } from '@shoootin/components-web';
import { ShColors, ShMediaQueries } from '@shoootin/design-tokens';
import { useFrontCountry } from '../../layout/frontContext';

const FR = {
  id: 'FR',
  cx: 483,
  cy: 317,
  r: 8,
  stroke: 4,
};
const IT = {
  id: 'IT',
  cx: 510,
  cy: 332,
  r: 5,
  stroke: 2.5,
};

const ES = {
  id: 'ES',
  cx: 464,
  cy: 340,
  r: 7,
  stroke: 3.5,
};
const BE = {
  id: 'BE',
  cx: 485,
  cy: 301,
  r: 4,
  stroke: 2,
};
const DE = {
  id: 'DE',
  cx: 505,
  cy: 294,
  r: 8,
  stroke: 4,
};

const CH = {
  id: 'CH',
  cx: 498,
  cy: 315,
  r: 4,
  stroke: 2,
};

const UK = {
  id: 'UK',
  cx: 477,
  cy: 292,
  r: 5,
  stroke: 2.5,
};
const CA = {
  id: 'CA',
  cx: 287,
  cy: 302,
  r: 11,
  stroke: 5,
};

const US = [
  {
    id: 'US1',
    cx: 267,
    cy: 342,
    r: 11,
    stroke: 5,
  },
  {
    id: 'US2',
    cx: 248,
    cy: 387,
    r: 5,
    stroke: 2.5,
  },
  {
    id: 'US3',
    cx: 142,
    cy: 360,
    r: 5,
    stroke: 2.5,
  },
  {
    id: 'US4',
    cx: 135,
    cy: 347,
    r: 7,
    stroke: 3.5,
  },
  {
    id: 'US5',
    cx: 133,
    cy: 315,
    r: 8,
    stroke: 4,
  },
  {
    id: 'US6',
    cx: 190,
    cy: 340,
    r: 7,
    stroke: 3.5,
  },
  {
    id: 'US7',
    cx: 230,
    cy: 333,
    r: 7,
    stroke: 3.5,
  },
  {
    id: 'US8',
    cx: 232,
    cy: 359,
    r: 6,
    stroke: 3,
  },
  {
    id: 'US9',
    cx: 202,
    cy: 373,
    r: 8,
    stroke: 4,
  },
];

export const HomeWorldMapSvg = () => {
  const country = useFrontCountry();

  const circles: ShWorldMapCircles[] = [FR, BE, IT, ES, DE, CH, UK, CA, ...US];

  return <ShSvgWorldMap circles={circles} country={country} />;
};
