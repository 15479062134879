import React from 'react';

const ChevronDownCircleSvg = (props: any) => (
  <svg
    className="chevron-down-circle"
    height={53}
    width={53}
    viewBox="0 0 53 53"
    fill="none"
    {...props}
  >
    <circle cx={26.5} cy={26.5} r={25.8} />
    <polyline points="34.4,24.4 26,32.9 17.6,24.4 " />
  </svg>
);

export default ChevronDownCircleSvg;
