import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { H4 } from '../../primitives/appTitle';
import SliderPlaySvg from '../svg/icon/sliderPlaySvg';
import SliderPauseSvg from '../svg/icon/sliderPauseSvg';
import ChevronDownSvg from '../svg/picto/chevronDownSvg';
import ChevronPrevSvg from '../svg/picto/chevronPrevSvg';
import ChevronNextSvg from '../svg/picto/chevronNextSvg';
import FullscreenSvg from '../svg/icon/fullscreenSvg';
import CloseSvg from '../svg/picto/closeSvg';
// @ts-ignore
import { FrontGallerySwiper } from 'legacyJs/gallery';
import {
  ShIntlMessageDefinition,
  ShText,
  useShIntlLocale,
} from '@shoootin/translations';
import { useLastDefinedValue } from '../../hooks/useLast';
import { getProfessionMessage } from '../../appMessageDefinitions';
import { ProfessionShootingType } from '../../appConstants';
import useHomeProfessionGalleries from '../../page/home/queries/useHomeProfessionGalleries';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';

// const useGallerySwiper = (content?: GalleryModalContent) => {
//   const galleryRef = useRef<HTMLDivElement>(null);
//   const enabled = !!content;
//   useEffect(() => {
//     if (enabled) {
//       new FrontGallerySwiper(galleryRef.current!);
//     }
//   }, [enabled]);
//   return galleryRef;
// };

const useGallerySwiper2 = (content?: GalleryModalContent2) => {
  const galleryRef = useRef<HTMLDivElement>(null);
  const enabled = !!content;
  useEffect(() => {
    if (enabled) {
      new FrontGallerySwiper(galleryRef.current!);
    }
  }, [enabled]);
  return galleryRef;
};

// type GalleryModalContent = {
//   label: MessageDefinition;
//   photoGallery: GalleryPhoto[];
//   initialPhoto?: GalleryPhoto;
// };

// type GalleryModalProps = {
//   content?: GalleryModalContent;
//   onClose: () => void;
// };

type GalleryModalContent2 = {
  label: ShIntlMessageDefinition;
  photoGallery: AppGatsbyImageFluid[];
  initialPhoto?: AppGatsbyImageFluid;
};

type GalleryModalProps2 = {
  content?: GalleryModalContent2;
  onClose: () => void;
};

// // TODO legacy component, should be cleaned
// const GalleryModal = ({ content, onClose }: GalleryModalProps) => {
//   const lastDefinedContent = useLastDefinedValue(content); // Hacky but  needed for legacy unmount animation
//   const galleryRef = useGallerySwiper(lastDefinedContent);
//   return (
//     <div
//       className={classnames('gallery', { active: !!content })}
//       ref={galleryRef}
//     >
//       {lastDefinedContent && (
//         <div className="gallery-content">
//           <a
//             className="gallery-close"
//             onClick={(e) => {
//               e.preventDefault();
//               onClose();
//             }}
//           >
//             <CloseSvg />
//           </a>
//           <H4 className="gallery-title">
//             <ShText message={lastDefinedContent.label} />
//           </H4>
//           <div className="swiper-container gallery__top">
//             <div className="swiper-wrapper">
//               {lastDefinedContent.photoGallery.map((image) => (
//                 <div
//                   key={image.largeSrc}
//                   className="gallery-slide swiper-slide swiper-lazy"
//                 >
//                   <img data-src={image.largeSrc} className="swiper-lazy" />
//                   <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
//                 </div>
//               ))}
//             </div>
//           </div>
//           <div className="gallery-nav">
//             <div className="gallery-toolbar">
//               <div className="gallery-nav__toggle">
//                 <ChevronDownSvg />
//               </div>
//               <div className="gallery-nav__button previous">
//                 <ChevronPrevSvg />
//               </div>
//               <div className="gallery-nav__button play">
//                 <SliderPlaySvg />
//                 <SliderPauseSvg />
//               </div>
//               <div className="gallery-nav__button next">
//                 <ChevronNextSvg />
//               </div>
//               <div className="gallery-nav__button fullscreen">
//                 <FullscreenSvg />
//               </div>
//             </div>
//             <div className="gallery-thumbs swiper-container">
//               <div className="swiper-wrapper">
//                 {lastDefinedContent.photoGallery.map((image) => (
//                   <div key={image.thumbnailSrc} className="swiper-slide">
//                     <img src={image.thumbnailSrc} alt="" />
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// TODO legacy component, should be cleaned
export const GalleryModal2 = ({ content, onClose }: GalleryModalProps2) => {
  const lastDefinedContent = useLastDefinedValue(content); // Hacky but  needed for legacy unmount animation
  const galleryRef = useGallerySwiper2(lastDefinedContent);
  return (
    <div
      className={classnames('gallery', { active: !!content })}
      ref={galleryRef}
    >
      {lastDefinedContent && (
        <div className="gallery-content">
          <a
            className="gallery-close"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <CloseSvg />
          </a>
          <H4 className="gallery-title">
            <ShText message={lastDefinedContent.label} />
          </H4>
          <div className="swiper-container gallery__top">
            <div className="swiper-wrapper">
              {lastDefinedContent.photoGallery.map((image, i) => (
                <div key={i} className="gallery-slide swiper-slide swiper-lazy">
                  {/* <img data-src={image.largeSrc} className="swiper-lazy" /> */}
                  <AppGatsbyImage
                    fluid={image}
                    style={{
                      // height: '100%',
                      width: '100%',
                      // objectFit: 'contain',
                      // objectPosition: 'center center',
                    }}
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
                </div>
              ))}
            </div>
          </div>
          <div className="gallery-nav">
            <div className="gallery-toolbar">
              <div className="gallery-nav__toggle">
                <ChevronDownSvg />
              </div>
              <div className="gallery-nav__button previous">
                <ChevronPrevSvg />
              </div>
              <div className="gallery-nav__button play">
                <SliderPlaySvg />
                <SliderPauseSvg />
              </div>
              <div className="gallery-nav__button next">
                <ChevronNextSvg />
              </div>
              <div className="gallery-nav__button fullscreen">
                <FullscreenSvg />
              </div>
            </div>
            <div className="gallery-thumbs swiper-container">
              <div className="swiper-wrapper">
                {lastDefinedContent.photoGallery.map((image, i) => (
                  <div key={i} className="swiper-slide">
                    {/* <img src={image.thumbnailSrc} alt="" /> */}
                    <div css={{ width: 177 }}>
                      <AppGatsbyImage
                        fluid={image}
                        style={
                          {
                            // height: '100%',
                            // width: '100%',
                            // objectFit: 'contain',
                            // objectPosition: 'center center',
                          }
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// export default GalleryModal;

export const ProfessionGalleryModal = ({
  selectedProfession,
  onClose,
}: {
  selectedProfession?: ProfessionShootingType;
  onClose: GalleryModalProps2['onClose'];
}) => {
  if (selectedProfession) {
    const locale = useShIntlLocale();
    // const photos = getPhotoGalleryOfLocale(selectedProfession, locale);

    const homeProfessionGalleries = useHomeProfessionGalleries(false, locale);
    const professionGalleryImages = homeProfessionGalleries[selectedProfession];

    return (
      /* <GalleryModal
        content={{
          label: getProfessionMessage(selectedProfession, 'title'),
          photoGallery: photos,
        }}
        onClose={onClose}
      /> */
      <GalleryModal2
        content={{
          label: getProfessionMessage(selectedProfession, 'title'),
          photoGallery: professionGalleryImages,
        }}
        onClose={onClose}
      />
    );
  } else return null;
};
