import { AppGatsbyImageFluid } from '../primitives/appImage';
import { graphql, useStaticQuery } from 'gatsby';
import { mapValues } from 'lodash';
import { getFluidImages } from '../appGatsbyImageUtils';
import {
  ProfessionGalleryImages,
  ProfessionImagesEmpty,
  Professions,
  ProfessionShootingType,
  ProfessionShootingTypeLocalized,
} from '../appConstants';
import { useMemo } from 'react';
import { ProfessionLastImagesQuery } from '../appGraphQLTypes';
import { ShLocale, ShLocaleConfigs } from '@shoootin/config';

type ProfessionLastImages = {
  [key in ProfessionShootingType]: AppGatsbyImageFluid[];
};

const useProfessionLastImages = (locale: ShLocale): ProfessionLastImages => {
  const data = useStaticQuery<ProfessionLastImagesQuery>(graphql`
    # we don't want traced svgs for next slider images (it's expensive to inline many images and grows bundle size)
    fragment ProfessionLastImage on FileConnection {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(
              maxWidth: 1681
              quality: 90
              # not too many breakpoints because there are many images
              # each breakpoint has a cost on the js/html size
              srcSetBreakpoints: [681, 1681]
            ) {
              # Only first images of each gallery has traced svgs
              # other images of each gallery only has less values
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }

    query ProfessionLastImages {
      residentialRealEstate: allFile(
        sort: { fields: name, order: ASC }
        skip: 10
        filter: { relativePath: { glob: "profession_residential-gallery-*" } }
      ) {
        ...ProfessionLastImage
      }
      residentialRealEstate_US: allFile(
        sort: { fields: name, order: ASC }
        skip: 10
        filter: {
          relativePath: { glob: "profession_residential_us-gallery-*" }
        }
      ) {
        ...ProfessionLastImage
      }
      commercialRealEstate: allFile(
        sort: { fields: name, order: ASC }
        skip: 10
        filter: { relativePath: { glob: "profession_commercial-gallery-*" } }
      ) {
        ...ProfessionLastImage
      }
      commercialRealEstate_US: allFile(
        sort: { fields: name, order: ASC }
        skip: 10
        filter: { relativePath: { glob: "profession_commercial_us-gallery-*" } }
      ) {
        ...ProfessionLastImage
      }
      architect: allFile(
        sort: { fields: name, order: ASC }
        skip: 10
        filter: { relativePath: { glob: "profession_archideco-gallery-*" } }
      ) {
        ...ProfessionLastImage
      }
      hotel: allFile(
        sort: { fields: name, order: ASC }
        skip: 10
        filter: { relativePath: { glob: "profession_hotel-gallery-*" } }
      ) {
        ...ProfessionLastImage
      }
      retail: allFile(
        sort: { fields: name, order: ASC }
        skip: 10
        filter: { relativePath: { glob: "profession_retail-gallery-*" } }
      ) {
        ...ProfessionLastImage
      }
      restaurant: allFile(
        sort: { fields: name, order: ASC }
        skip: 10
        filter: { relativePath: { glob: "profession_restaurant-gallery-*" } }
      ) {
        ...ProfessionLastImage
      }
    }
  `);

  return useMemo(() => {
    const images: ProfessionGalleryImages = { ...ProfessionImagesEmpty };

    Professions.forEach((professionKey) => {
      const localizedKey = locale
        ? (`${professionKey}_${ShLocaleConfigs[locale].country}` as ProfessionShootingTypeLocalized)
        : professionKey;

      images[professionKey] = data[localizedKey] ?? data[professionKey];
    });

    return mapValues(images, (_, key) => getFluidImages(images, key));
  }, [data, locale]);

  //   return mapValues(data, (_, key) => getFluidImages(data, key));
  // }, [data]);
};

export default useProfessionLastImages;
