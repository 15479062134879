import React from 'react';

const SliderPlaySvg = (props: any) => (
  <svg
    className="slider-play"
    height={16}
    width={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M4.4,14L4.4,2l7.3,5.9L4.4,14z" />
  </svg>
);

export default SliderPlaySvg;
