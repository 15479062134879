import React from 'react';
import classnames from 'classnames';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';

const HeroSlide = ({
  fluidImage,
  first = true,
}: {
  fluidImage: AppGatsbyImageFluid;
  first?: boolean;
}) => {
  return (
    <div
      className={classnames('hero-slider__slide', !first ? 'focus' : '')}
      style={first ? { zIndex: 0 } : {}}
    >
      <div className="hero-frame">
        <AppGatsbyImage
          className="hero-background"
          fluid={fluidImage}
          loading={first ? 'eager' : 'lazy'}
          style={{
            position: 'absolute',
          }}
        />
      </div>
    </div>
  );
};

export default HeroSlide;
