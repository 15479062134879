import React from 'react';

const HeroNavSvg = (props: any) => (
  <svg
    className="hero-nav"
    height={50}
    width={50}
    viewBox="0 0 50 50"
    fill="none"
    {...props}
  >
    <g className="hero-nav-bullet">
      <line className="hero-nav-accent" x1={0} y1={10.5} x2={12} y2={10.5} />
      <line className="hero-nav-base" x1={30} y1={10.5} x2={68} y2={10.5} />
    </g>
    <g className="hero-nav-bullet">
      <line className="hero-nav-accent" x1={0} y1={20.5} x2={12} y2={20.5} />
      <line className="hero-nav-base" x1={30} y1={20.5} x2={68} y2={20.5} />
    </g>
    <g className="hero-nav-bullet">
      <line className="hero-nav-accent" x1={0} y1={30.5} x2={12} y2={30.5} />
      <line className="hero-nav-base" x1={30} y1={30.5} x2={68} y2={30.5} />
    </g>
    <g className="hero-nav-bullet">
      <line className="hero-nav-accent" x1={0} y1={40.5} x2={12} y2={40.5} />
      <line className="hero-nav-base" x1={30} y1={40.5} x2={68} y2={40.5} />
    </g>
  </svg>
);

export default HeroNavSvg;
