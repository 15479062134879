import { AppGatsbyImageFluid } from '../primitives/appImage';
import { graphql, useStaticQuery } from 'gatsby';
import { mapValues } from 'lodash';
import { forceImageMaxWidth, getFluidImage } from '../appGatsbyImageUtils';
import { ProfessionFirstImagesQuery } from '../appGraphQLTypes';
import {
  ProfessionImagesEmpty,
  Professions,
  ProfessionShootingType,
  ProfessionShootingTypeLocalized,
} from '../appConstants';
import { useMemo } from 'react';
import { ShLocale, ShLocaleConfigs } from '@shoootin/config';

type ProfessionFirstImages = {
  [key in ProfessionShootingType]: AppGatsbyImageFluid;
};

const useProfessionFirstImages = (locale: ShLocale): ProfessionFirstImages => {
  const data = useStaticQuery<ProfessionFirstImagesQuery>(graphql`
    fragment ProfessionFirstImage on File {
      id
      name
      childImageSharp {
        fluid(
          maxWidth: 1681
          quality: 85
          # Home: 681 maxWidth
          # GalleryGrid: 400/610/810 maxWidths
          # CoverMetiers: 200 maxWidth
          srcSetBreakpoints: [200, 400, 610, 700, 810, 1000, 1220, 1400, 1620]
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    query ProfessionFirstImages {
      architect: file(
        relativePath: { eq: "profession_archideco-gallery-1.jpg" }
      ) {
        ...ProfessionFirstImage
      }
      commercialRealEstate: file(
        relativePath: { eq: "profession_commercial-gallery-1.jpg" }
      ) {
        ...ProfessionFirstImage
      }
      commercialRealEstate_US: file(
        relativePath: { eq: "profession_commercial_us-gallery-1.jpg" }
      ) {
        ...ProfessionFirstImage
      }
      hotel: file(relativePath: { eq: "profession_hotel-gallery-1.jpg" }) {
        ...ProfessionFirstImage
      }
      residentialRealEstate: file(
        relativePath: { eq: "profession_residential-gallery-1.jpg" }
      ) {
        ...ProfessionFirstImage
      }
      residentialRealEstate_US: file(
        relativePath: { eq: "profession_residential_us-gallery-1.jpg" }
      ) {
        ...ProfessionFirstImage
      }
      retail: file(relativePath: { eq: "profession_retail-gallery-1.jpg" }) {
        ...ProfessionFirstImage
      }
      restaurant: file(
        relativePath: { eq: "profession_restaurant-gallery-1.jpg" }
      ) {
        ...ProfessionFirstImage
      }
    }
  `);

  return useMemo(() => {
    const images = { ...ProfessionImagesEmpty };

    Professions.forEach((professionKey) => {
      const localizedKey = locale
        ? (`${professionKey}_${ShLocaleConfigs[locale].country}` as ProfessionShootingTypeLocalized)
        : professionKey;

      const firstImage = data[localizedKey] ?? data[professionKey];

      images[professionKey] = getFluidImage(
        firstImage as any,
        `${firstImage?.name} profession first image not found`,
      );
    });

    return images;

    // return mapValues(data, (file, key) =>
    //   getFluidImage(file as any, `${key} profession first image not found`),
    // );
  }, [data, locale]);
};

export default useProfessionFirstImages;
